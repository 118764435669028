import axiosMFSInstance from "../axios_mfs";
import * as endpoints from "./endpoints_mfs";
import { AUTHORIZATION_KEY } from "../global_constants";

export const getSingleManifestLastLot = (manifestId) => {
  let path = endpoints.GET_LOT_SECTION_API_PATH.replace("{manifestId}", manifestId);
  return axiosMFSInstance.get(path);
};
export const startReceivingDownload = (payload) => {
  let path = endpoints.RECEIVING_DOWNLOAD_API_PATH;
  return axiosMFSInstance.post(path, payload);
};