export const ITEM_MODE_INVENTORY = "Inventory";

export const RECEIVING_MODE_SOURCE_TYPE = "Receiving";

export const MANIFEST_ITEM_DOWNLOAD_TYPES = [
    {
        value: "ManifestWithPhotos",
        label: "Manifest with Photos"
    },
    {
        value: "ManifestOnly",
        label: "Manifest Only"
    },
    {
        value: "FeaturedOnly",
        label: "Featured Items"
    }
]