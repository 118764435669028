import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";

import { Checkbox, Table, Modal, Upload, Button, Form, Input, Card, Space, InputNumber, Select, Col, Row, message, Alert, DatePicker, Popover, Typography, Image } from "antd";
import { registerUser } from "../../actions";
import { UserOutlined, LockOutlined, InboxOutlined, ShopOutlined } from "@ant-design/icons";

const RegisterPage = () => {

  const history = useHistory();
  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);

  function onSubmit(values) {
    setProcessing(true);
    registerUser(values)
      .then((response) => {
        setProcessing(false);
        message.success("Successfully registered new account!");
        history.push("/");
      })
      .catch((err) => {
        setProcessing(false);
        message.error(err.response.data.detail);
      });
  }
  return (
    <div className="login-page">
      <Card bordered>
        <p className="text-2xl semi-bold mb-16">Register</p>
        <Form form={form} onFinish={(values) => onSubmit(values)}>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              size="middle"
              prefix={<InboxOutlined />}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            name="account_name"
          >
            <Input
              size="middle"
              prefix={<ShopOutlined />}
              placeholder="Account Name"
            />
          </Form.Item>

          <Form.Item
            name="first_name"
          >
            <Input
              size="middle"
              prefix={<UserOutlined />}
              placeholder="First Name"
            />
          </Form.Item>

          <Form.Item
            name="last_name"
          >
            <Input
              size="middle"
              prefix={<UserOutlined />}
              placeholder="Last Name"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              size="middle"
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Please Confirm your Password!",
              },
            ]}
          >
            <Input.Password
              size="middle"
              prefix={<LockOutlined />}
              placeholder="Confirm Password"
            />
          </Form.Item>

          <Form.Item noStyle>
            <Button
              block
              type="primary"
              className="mt-8"
              htmlType="submit"
              loading={processing}
            >
              Register
            </Button>
          </Form.Item>
        </Form>
        <p style={
          {
            marginTop: "15px"
          }
        }>Already have an Account? Click <Link to={"/"}>Here</Link> to Login</p>
      </Card>
    </div>
  );
};

export default RegisterPage;
