import { useEffect, useState } from "react";
import { Modal, Form, Input, message, Checkbox, Select, InputNumber, DatePicker, Radio } from "antd";
import { startReceivingDownload } from "../../actions/actions_mfs.js";
import dayjs from "dayjs";

import { MANIFEST_ITEM_DOWNLOAD_TYPES } from "../../constants";
import { DATETIME_BACKEND_FORMAT, DISPLAY_FULL_DATETIME_FORMAT } from "../../global_constants";

const { RangePicker } = DatePicker;

const StartDownloadModal = ({ isModalOpen, setIsModalOpen, receivingDownload }) => {
  const [form] = Form.useForm();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const startDownload = (values) => {
    let selectedMode = values.item_download_type;
    /*
      Specified by Evan:
      Manifest With Photos
      - all manifest items with photos
      - featured only items with photos

      Manifest only
      - all manifest items without photos
      - featured items without photos

      Featured items:
      - featured items with photos
    */
    if (selectedMode === "ManifestWithPhotos") {
      values.include_photos = true;
      values.item_download_type = "All";
    } else if (selectedMode === "ManifestOnly") {
      values.include_photos = false;
      values.item_download_type = "All";
    } else if (selectedMode === "FeaturedOnly") {
      values.include_photos = true;
      values.item_download_type = "OnlyFeatured";
    } else {
      message.error("Invalid download type - please contact support");
      return;
    }

    let functionToCall = null;
    if (receivingDownload) {
      functionToCall = startReceivingDownload;
    }
    else {
      message.error("Invalid download type - please contact support");
      return;
    }
    let startDatetime = values?.start_end_datetime?.[0];
    let endDatetime = values?.start_end_datetime?.[1];
    if (startDatetime) {
      values.start_datetime = startDatetime.format(DATETIME_BACKEND_FORMAT);
    }
    if (endDatetime) {
      values.end_datetime = endDatetime.format(DATETIME_BACKEND_FORMAT);
    }

    setIsDownloading(true);
    functionToCall(values).then((response) => {
      setIsDownloading(false);
      const { data } = response;
      const { id } = data;
      setIsModalOpen(false);
      message.success("Download started successfully. You can use the manyfastScan portal to monitor the download.", 10);
    }).catch((err) => {
      setIsDownloading(false);
      message.error(err?.response?.data?.detail);
    })
  };

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        form.resetFields();
      }, 50);
    }
  }, [isModalOpen]);

  return (
    <Modal
      title={`Start Download - Receiving Items`}
      okText={"Download"}
      cancelText="Close"
      open={isModalOpen}
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}
      confirmLoading={isDownloading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => startDownload(values)}
        initialValues={{
          item_download_type: MANIFEST_ITEM_DOWNLOAD_TYPES[0].value, // ManifestWithPhotos
          start_end_datetime: [dayjs().add(-14, 'd'), dayjs()]
        }}
      >
        <Form.Item
          label="Download Type"
          name="item_download_type"
          rules={[{ required: true, message: "Please select download type" }]}
        >
          <Radio.Group>
            {MANIFEST_ITEM_DOWNLOAD_TYPES.map((item) => {
              return (
                <Radio value={item.value}>{item.label}</Radio>
              )
            })}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Created/Updated Start - End"
          name="start_end_datetime"
        >
          <RangePicker
            allowClear
            format={DISPLAY_FULL_DATETIME_FORMAT}
            showTime
            onChange={(dates) => {
              console.log(dates);
            }}
            presets={[
              {
                label: 'Yesterday',
                value: [dayjs().add(-1, 'd'), dayjs()],
              },
              {
                label: 'Last 7 Days',
                value: [dayjs().add(-7, 'd'), dayjs()],
              },
              {
                label: 'Last 14 Days',
                value: [dayjs().add(-14, 'd'), dayjs()],
              },
              {
                label: 'Last 30 Days',
                value: [dayjs().add(-30, 'd'), dayjs()],
              },
              {
                label: 'Last 90 Days',
                value: [dayjs().add(-90, 'd'), dayjs()],
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StartDownloadModal;
