import React from "react";
import {
  AuditOutlined,
  CarOutlined,
  CloudDownloadOutlined,
  CodeSandboxOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  HomeOutlined,
  InboxOutlined,
  PercentageOutlined,
  ShopOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";

import NotFoundPage from "./pages/404Page";

import DashboardLayout from "./layouts/dashboard";
import Login from "./pages/login";
import RegisterPage from "./pages/register";

const HomePageComponent = React.lazy(() =>
  import("./pages/homePage")
);
const LocationsPageComponent = React.lazy(() =>
  import("./pages/locations")
);
const BuildingsPageComponent = React.lazy(() =>
  import("./pages/buildings")
);
const ShelvesPageComponent = React.lazy(() =>
  import("./pages/shelves")
);
const BinsPageComponent = React.lazy(() =>
  import("./pages/bins")
);
const ProductsPageComponent = React.lazy(() =>
  import("./pages/products")
);
const DeliveryLoadsPageComponent = React.lazy(() =>
  import("./pages/deliveryLoads")
);
const VendorsPageComponent = React.lazy(() =>
  import("./pages/vendors")
);
const ProductDiscountRulesPageComponent = React.lazy(() =>
  import("./pages/productDiscountRules")
);
const InventoryGroupsPageComponent = React.lazy(() =>
  import("./pages/inventoryGroups")
);
const ReceivingItemsPageComponent = React.lazy(() =>
  import("./pages/receivingItems")
);
const PalletsPageComponent = React.lazy(() =>
  import("./pages/pallets")
);

export const INDEX_ROUTE = "/";
export const REGISTER_ROUTE = "/register";
export const LOCATIONS_ROUTE = "/locations";
export const BUILDINGS_ROUTE = "/buildings";
export const SHELVES_ROUTE = "/shelves";
export const BINS_ROUTE = "/bins";
export const PRODUCTS_ROUTE = "/products";
export const DELIVERY_LOADS_ROUTE = "/delivery-loads";
export const VENDORS_ROUTE = "/vendors";
export const PRODUCT_DISCOUNT_RULES_ROUTE = "/product-discount-rules";
export const INVENTORY_GROUPS_ROUTE = "/inventory-groups";
export const PALLETS_ROUTE = "/pallets";
export const RECEIVING_ROUTE = "/receiving";

export const NON_LOGIN_ROUTES = [
  {
    exact: true,
    name: "login",
    path: INDEX_ROUTE,
    component: Login,
  },
  {
    exact: true,
    name: "register",
    path: REGISTER_ROUTE,
    component: RegisterPage,
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [
  {
    name: "layout",
    path: INDEX_ROUTE,
    component: DashboardLayout,
    routes: [
      {
        exact: true,
        name: "home",
        path: INDEX_ROUTE,
        component: HomePageComponent,
      },
      {
        exact: true,
        name: "locations",
        path: LOCATIONS_ROUTE,
        component: LocationsPageComponent,
      },
      {
        exact: true,
        name: "buildings",
        path: BUILDINGS_ROUTE,
        component: BuildingsPageComponent,
      },
      {
        exact: true,
        name: "shelves",
        path: SHELVES_ROUTE,
        component: ShelvesPageComponent,
      },
      {
        exact: true,
        name: "bins",
        path: BINS_ROUTE,
        component: BinsPageComponent,
      },
      {
        exact: true,
        name: "products",
        path: PRODUCTS_ROUTE,
        component: ProductsPageComponent,
      },
      {
        exact: true,
        name: "delivery-loads",
        path: DELIVERY_LOADS_ROUTE,
        component: DeliveryLoadsPageComponent,
      },
      {
        exact: true,
        name: "vendors",
        path: VENDORS_ROUTE,
        component: VendorsPageComponent,
      },
      {
        exact: true,
        name: "product-discount-rules",
        path: PRODUCT_DISCOUNT_RULES_ROUTE,
        component: ProductDiscountRulesPageComponent,
      },
      {
        exact: true,
        name: "inventory-groups",
        path: INVENTORY_GROUPS_ROUTE,
        component: InventoryGroupsPageComponent,
      },
      {
        exact: true,
        name: "receiving-items",
        path: RECEIVING_ROUTE,
        component: ReceivingItemsPageComponent,
      },
      {
        exact: true,
        name: "pallets",
        path: PALLETS_ROUTE,
        component: PalletsPageComponent,
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const SIDEBAR_ITEMS = [
  {
    name: "home",
    label: "Dashboard",
    route: INDEX_ROUTE,
    icon: <DashboardOutlined />,
  },
  {
    name: "inventory",
    label: "Inventory",
    icon: <CodeSandboxOutlined />,
    submenu: [
      {
        name: "inventory-items",
        label: "Inventory Items",
        route: PRODUCTS_ROUTE,
        icon: <CodeSandboxOutlined />,
      },
      {
        name: "pallets",
        label: "Pallets",
        route: PALLETS_ROUTE,
        icon: <CodeSandboxOutlined />,
      },
      {
        name: "receiving",
        label: "Receiving",
        route: RECEIVING_ROUTE,
        icon: <AuditOutlined />,
      },
      {
        name: "inventory-groups",
        label: "Inventory Groups",
        route: INVENTORY_GROUPS_ROUTE,
        icon: <SnippetsOutlined />,
      },
      {
        name: "delivery-loads",
        label: "Delivery Loads",
        route: DELIVERY_LOADS_ROUTE,
        icon: <CarOutlined />,
      },
    ]
  },
  {
    name: "download-receiving",
    label: "Download Receiving",
    icon: <CloudDownloadOutlined />,
    downloadReceiving: true,
  },
  {
    name: "location-settings",
    label: "Location Settings",
    icon: <ShopOutlined />,
    submenu: [
      {
        name: "locations",
        label: "Locations",
        route: LOCATIONS_ROUTE,
        icon: <ShopOutlined />,
      },
      {
        name: "buildings",
        label: "Buildings",
        route: BUILDINGS_ROUTE,
        icon: <HomeOutlined />,
      },
      {
        name: "shelves",
        label: "Shelves",
        route: SHELVES_ROUTE,
        icon: <DatabaseOutlined />,
      },
      {
        name: "bins",
        label: "Bins",
        route: BINS_ROUTE,
        icon: <InboxOutlined />,
      },
    ]
  },
  {
    name: "discount-settings",
    label: "Discount Settings",
    icon: <PercentageOutlined />,
    submenu: [
      {
        name: "product-discount-rules",
        label: "Discount Engine",
        route: PRODUCT_DISCOUNT_RULES_ROUTE,
        icon: <PercentageOutlined />,
      },
    ]
  },
  {
    name: "vendors",
    label: "Vendors",
    route: VENDORS_ROUTE,
    icon: <ShopOutlined />,
  },
];
