import instance from "../axios";
import axiosMFSInstance from "../axios_mfs";
import * as endpoints from "./endpoints";
import { AUTHORIZATION_KEY } from "../global_constants";

export const registerUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  delete axiosMFSInstance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.REGISTER_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  delete axiosMFSInstance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.LOGIN_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access_token}`;
        axiosMFSInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access_token}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAuthUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axiosMFSInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      instance
        .get(endpoints.AUTH_USER_API_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const getAllLocations = () => {
  let path = endpoints.GET_ALL_LOCATIONS_API_PATH;
  return instance.get(path);
};
export const getSingleLocation = (locationId) => {
  let path = endpoints.GET_SINGLE_LOCATION_API_PATH.replace("{locationId}", locationId);
  return instance.get(path);
};
export const addLocation = (payload) => {
  let path = endpoints.ADD_LOCATIONS_API_PATH;
  return instance.post(path, payload);
};
export const editLocation = (payload, locationId) => {
  let path = endpoints.EDIT_LOCATION_API_PATH.replace("{locationId}", locationId);
  return instance.post(path, payload);
};
export const deleteLocation = (locationId) => {
  let path = endpoints.DELETE_LOCATION_API_PATH.replace("{locationId}", locationId);
  return instance.delete(path);
};

export const getAllBuildings = () => {
  let path = endpoints.GET_ALL_BUILDINGS_API_PATH;
  return instance.get(path);
};
export const getSingleBuilding = (buildingId) => {
  let path = endpoints.GET_SINGLE_BUILDING_API_PATH.replace("{buildingId}", buildingId);
  return instance.get(path);
};
export const addBuilding = (payload) => {
  let path = endpoints.ADD_BUILDINGS_API_PATH;
  return instance.post(path, payload);
};
export const editBuilding = (payload, buildingId) => {
  let path = endpoints.EDIT_BUILDING_API_PATH.replace("{buildingId}", buildingId);
  return instance.post(path, payload);
};
export const deleteBuilding = (buildingId) => {
  let path = endpoints.DELETE_BUILDING_API_PATH.replace("{buildingId}", buildingId);
  return instance.delete(path);
};

export const getAllShelves = () => {
  let path = endpoints.GET_ALL_SHELVES_API_PATH;
  return instance.get(path);
};
export const getSingleShelf = (shelfId) => {
  let path = endpoints.GET_SINGLE_SHELF_API_PATH.replace("{shelfId}", shelfId);
  return instance.get(path);
};
export const addShelf = (payload) => {
  let path = endpoints.ADD_SHELVES_API_PATH;
  return instance.post(path, payload);
};
export const editShelf = (payload, shelfId) => {
  let path = endpoints.EDIT_SHELF_API_PATH.replace("{shelfId}", shelfId);
  return instance.post(path, payload);
};
export const deleteShelf = (shelfId) => {
  let path = endpoints.DELETE_SHELF_API_PATH.replace("{shelfId}", shelfId);
  return instance.delete(path);
};

export const getAllBins = () => {
  let path = endpoints.GET_ALL_BINS_API_PATH;
  return instance.get(path);
};
export const getSingleBin = (binId) => {
  let path = endpoints.GET_SINGLE_BIN_API_PATH.replace("{binId}", binId);
  return instance.get(path);
};
export const addBin = (payload) => {
  let path = endpoints.ADD_BINS_API_PATH;
  return instance.post(path, payload);
};
export const editBin = (payload, binId) => {
  let path = endpoints.EDIT_BIN_API_PATH.replace("{binId}", binId);
  return instance.post(path, payload);
};
export const deleteBin = (binId) => {
  let path = endpoints.DELETE_BIN_API_PATH.replace("{binId}", binId);
  return instance.delete(path);
};

export const getAllProducts = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_PRODUCTS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSingleProduct = (productId) => {
  let path = endpoints.GET_SINGLE_PRODUCT_API_PATH.replace("{productId}", productId);
  return instance.get(path);
};
export const addProduct = (payload) => {
  let path = endpoints.ADD_PRODUCTS_API_PATH;
  return instance.post(path, payload);
};
export const editProduct = (payload, productId) => {
  let path = endpoints.EDIT_PRODUCT_API_PATH.replace("{productId}", productId);
  return instance.post(path, payload);
};
export const deleteProduct = (productId) => {
  let path = endpoints.DELETE_PRODUCT_API_PATH.replace("{productId}", productId);
  return instance.delete(path);
};
export const applyDiscountToProduct = (productId, discountPrice) => {
  let path = endpoints.APPLY_DISCOUNT_PRICE_TO_PRODUCT_API_PATH.replace("{productId}", productId).replace("{discountPrice}", discountPrice);
  return instance.get(path);
}
export const changeInventoryGroup = (productId, inventoryGroupId) => {
  let path = endpoints.CHANGE_INVENTORY_GROUP_FOR_PRODUCT_API_PATH.replace("{productId}", productId).replace("{inventoryGroupId}", inventoryGroupId);
  return instance.get(path);
}
export const transferFromInventoryToInventory = (productId, shelfId, binId = null) => {
  let path = endpoints.TRANSFER_FROM_INVENTORY_TO_INVENTORY_PRODUCT_API_PATH.replace("{productId}", productId).replace("{shelfId}", shelfId);
  if (binId) path = path + `&bin_id=${binId}`;
  return instance.get(path);
}
export const transferFromInventoryToManifest = (productId, manifestId, lot, section) => {
  let path = endpoints.TRANSFER_FROM_INVENTORY_TO_MANIFEST_PRODUCT_API_PATH.replace("{productId}", productId).replace("{manifestId}", manifestId).replace("{lot}", lot);
  if (section && section !== "" && section !== " ") path = path + `&section=${section}`;
  return instance.get(path);
}
export const markProductAsSold = (productId) => {
  let path = endpoints.MARK_PRODUCT_AS_SOLD_API_PATH.replace("{productId}", productId);
  return instance.get(path);
}
export const markProductAsDamaged = (payload, productId) => {
  let path = endpoints.MARK_PRODUCT_AS_DAMAGED_API_PATH.replace("{productId}", productId);
  return instance.post(path, payload);
}
export const transferItemFromAnyToRestock = (productId, buildingId) => {
  let path = endpoints.TRANSFER_FROM_ANY_TO_RESTOCK_API_PATH.replace("{productId}", productId).replace("{buildingId}", buildingId);
  return instance.get(path);
}

export const getAllProductConditions = () => {
  let path = endpoints.GET_ALL_PRODUCT_CONDITIONS_API_PATH;
  return instance.get(path);
};
export const getSingleProductCondition = (productConditionId) => {
  let path = endpoints.GET_SINGLE_PRODUCT_CONDITION_API_PATH.replace("{productConditionId}", productConditionId);
  return instance.get(path);
};
export const addProductCondition = (payload) => {
  let path = endpoints.ADD_PRODUCT_CONDITIONS_API_PATH;
  return instance.post(path, payload);
};
export const editProductCondition = (payload, productConditionId) => {
  let path = endpoints.EDIT_PRODUCT_CONDITION_API_PATH.replace("{productConditionId}", productConditionId);
  return instance.post(path, payload);
};
export const deleteProductCondition = (productConditionId) => {
  let path = endpoints.DELETE_PRODUCT_CONDITION_API_PATH.replace("{productConditionId}", productConditionId);
  return instance.delete(path);
};

export const getAllCategories = () => {
  let path = endpoints.GET_ALL_CATEGORIES_API_PATH;
  return instance.get(path);
};
export const getSingleCategory = (productCategoryId) => {
  let path = endpoints.GET_SINGLE_CATEGORY_API_PATH.replace("{productCategoryId}", productCategoryId);
  return instance.get(path);
};
export const addCategory = (payload) => {
  let path = endpoints.ADD_CATEGORIES_API_PATH;
  return instance.post(path, payload);
};
export const editCategory = (payload, productCategoryId) => {
  let path = endpoints.EDIT_CATEGORY_API_PATH.replace("{productCategoryId}", productCategoryId);
  return instance.post(path, payload);
};
export const deleteCategory = (productCategoryId) => {
  let path = endpoints.DELETE_CATEGORY_API_PATH.replace("{productCategoryId}", productCategoryId);
  return instance.delete(path);
};

export const getAllDepartments = () => {
  let path = endpoints.GET_ALL_DEPARTMENTS_API_PATH;
  return instance.get(path);
};
export const getSingleDepartment = (departmentId) => {
  let path = endpoints.GET_SINGLE_DEPARTMENT_API_PATH.replace("{departmentId}", departmentId);
  return instance.get(path);
};
export const addDepartment = (payload) => {
  let path = endpoints.ADD_DEPARTMENTS_API_PATH;
  return instance.post(path, payload);
};
export const editDepartment = (payload, departmentId) => {
  let path = endpoints.EDIT_DEPARTMENT_API_PATH.replace("{departmentId}", departmentId);
  return instance.post(path, payload);
};
export const deleteDepartment = (departmentId) => {
  let path = endpoints.DELETE_DEPARTMENT_API_PATH.replace("{departmentId}", departmentId);
  return instance.delete(path);
};

export const getAllDeliveryLoads = () => {
  let path = endpoints.GET_ALL_DELIVERY_LOADS_API_PATH;
  return instance.get(path);
};
export const getSingleDeliveryLoad = (deliveryLoadId) => {
  let path = endpoints.GET_SINGLE_DELIVERY_LOAD_API_PATH.replace("{deliveryLoadId}", deliveryLoadId);
  return instance.get(path);
};
export const addDeliveryLoad = (payload) => {
  let path = endpoints.ADD_DELIVERY_LOADS_API_PATH;
  return instance.post(path, payload);
};
export const editDeliveryLoad = (payload, deliveryLoadId) => {
  let path = endpoints.EDIT_DELIVERY_LOAD_API_PATH.replace("{deliveryLoadId}", deliveryLoadId);
  return instance.post(path, payload);
};
export const deleteDeliveryLoad = (deliveryLoadId) => {
  let path = endpoints.DELETE_DELIVERY_LOAD_API_PATH.replace("{deliveryLoadId}", deliveryLoadId);
  return instance.delete(path);
};

export const getAllVendors = () => {
  let path = endpoints.GET_ALL_VENDORS_API_PATH;
  return instance.get(path);
};
export const getSingleVendor = (vendorId) => {
  let path = endpoints.GET_SINGLE_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.get(path);
};
export const addVendor = (payload) => {
  let path = endpoints.ADD_VENDORS_API_PATH;
  return instance.post(path, payload);
};
export const editVendor = (payload, vendorId) => {
  let path = endpoints.EDIT_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.post(path, payload);
};
export const deleteVendor = (vendorId) => {
  let path = endpoints.DELETE_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.delete(path);
};

export const getAllIntegrations = () => {
  let path = endpoints.GET_ALL_INTEGRATIONS_API_PATH;
  return instance.get(path);
};

export const getAllProductDiscountRules = () => {
  let path = endpoints.GET_ALL_PRODUCT_DISCOUNT_RULES_API_PATH;
  return instance.get(path);
};
export const getSingleProductDiscountRule = (productDiscountRuleId) => {
  let path = endpoints.GET_SINGLE_PRODUCT_DISCOUNT_RULE_API_PATH.replace("{productDiscountRuleId}", productDiscountRuleId);
  return instance.get(path);
};
export const addProductDiscountRule = (payload) => {
  let path = endpoints.ADD_PRODUCT_DISCOUNT_RULES_API_PATH;
  return instance.post(path, payload);
};
export const editProductDiscountRule = (payload, productDiscountRuleId) => {
  let path = endpoints.EDIT_PRODUCT_DISCOUNT_RULE_API_PATH.replace("{productDiscountRuleId}", productDiscountRuleId);
  return instance.post(path, payload);
};
export const deleteProductDiscountRule = (productDiscountRuleId) => {
  let path = endpoints.DELETE_PRODUCT_DISCOUNT_RULE_API_PATH.replace("{productDiscountRuleId}", productDiscountRuleId);
  return instance.delete(path);
};
export const getProductDiscountRulesThatApply = (productItemId) => {
  let path = endpoints.GET_PRODUCT_DISCOUNT_RULES_THAT_APPLY_API_PATH.replace("{productItemId}", productItemId);
  return instance.get(path);
}

export const getAllInventoryGroups = () => {
  let path = endpoints.GET_ALL_INVENTORY_GROUPS_API_PATH;
  return instance.get(path);
};
export const getSingleInventoryGroup = (inventoryGroupId) => {
  let path = endpoints.GET_SINGLE_INVENTORY_GROUP_API_PATH.replace("{inventoryGroupId}", inventoryGroupId);
  return instance.get(path);
};
export const addInventoryGroup = (payload) => {
  let path = endpoints.ADD_INVENTORY_GROUPS_API_PATH;
  return instance.post(path, payload);
};
export const editInventoryGroup = (payload, inventoryGroupId) => {
  let path = endpoints.EDIT_INVENTORY_GROUP_API_PATH.replace("{inventoryGroupId}", inventoryGroupId);
  return instance.post(path, payload);
};
export const deleteInventoryGroup = (inventoryGroupId) => {
  let path = endpoints.DELETE_INVENTORY_GROUP_API_PATH.replace("{inventoryGroupId}", inventoryGroupId);
  return instance.delete(path);
};

export const getAllManifests = () => {
  let path = endpoints.GET_ALL_MANIFESTS_API_PATH;
  return instance.get(path);
};
export const getSingleManifest = (manifestId) => {
  let path = endpoints.GET_SINGLE_MANIFEST_API_PATH.replace("{inventoryGroupId}", manifestId);
  return instance.get(path);
};

export const getAllManifestGroups = () => {
  let path = endpoints.GET_ALL_MANIFEST_GROUPS_API_PATH;
  return instance.get(path);
};
export const getSingleManifestGroup = (manifestGroupId) => {
  let path = endpoints.GET_SINGLE_MANIFEST_GROUP_API_PATH.replace("{inventoryGroupId}", manifestGroupId);
  return instance.get(path);
};

export const getAllInventorySupportingData = () => {
  let path = endpoints.GET_INVENTORY_ITEMS_SUPPORTING_DATA_API_PATH;
  return instance.get(path);
}
export const getAllManifestSupportingData = () => {
  let path = endpoints.GET_MANIFESTS_SUPPORTING_DATA_API_PATH;
  return instance.get(path);
}

export const getHistoryForInventoryProductItem = (inventoryProductId) => {
  let path = endpoints.GET_HISTORY_FOR_INVENTORY_PRODUCT_ITEM_API_PATH.replace("{inventoryItemId}", inventoryProductId);
  return instance.get(path);
}

export const getAllPallets = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_PALLETS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSinglePallet = (palletId) => {
  let path = endpoints.GET_SINGLE_PALLET_API_PATH.replace("{palletId}", palletId);
  return instance.get(path);
};
export const addPallet = (payload) => {
  let path = endpoints.ADD_PALLETS_API_PATH;
  return instance.post(path, payload);
};
export const editPallet = (payload, palletId) => {
  let path = endpoints.EDIT_PALLET_API_PATH.replace("{palletId}", palletId);
  return instance.post(path, payload);
};
export const markPalletAsDamaged = (payload, palletId) => {
  let path = endpoints.MARK_PALLET_AS_DAMAGED_API_PATH.replace("{palletId}", palletId);
  return instance.post(path, payload);
};
export const markPalletAsSold = (palletId) => {
  let path = endpoints.MARK_PALLET_AS_SOLD_API_PATH.replace("{palletId}", palletId);
  return instance.get(path);
};
export const changeManifestGroupForPallet = (palletId, manifestGroupId = null) => {
  let path = endpoints.CHANGE_MANIFEST_GROUP_FOR_PALLET_API_PATH.replace("{palletId}", palletId);
  if (manifestGroupId) path = path + `&manifest_group_id=${manifestGroupId}`;
  return instance.get(path);
};
export const transferPalletToManifest = (palletId, manifestId, productConditionId, lotNr, section) => {
  let path = endpoints.TRANSFER_PALLET_TO_MANIFEST_API_PATH;

  path = path.replace("{palletId}", palletId);
  path = path.replace("{manifestId}", manifestId);
  path = path.replace("{productConditionId}", productConditionId);
  path = path.replace("{lot}", lotNr);
  path = path.replace("{section}", section);

  return instance.get(path);
};
export const deletePallet = (palletId) => {
  let path = endpoints.DELETE_PALLET_API_PATH.replace("{palletId}", palletId);
  return instance.delete(path);
};