import axios from "axios";

let headers = {
  "Content-Type": "application/json",
  "Client-Type": "inventory-module-portal",
};

const axiosMFSInstance = axios.create({
  headers,
  timeout: 16000000,
  baseURL: `${process.env.REACT_APP_MFS_API_HOST}/api/v1/`,
});

export default axiosMFSInstance;
