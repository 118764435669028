export const REGISTER_API_PATH = "register/new-account";

export const LOGIN_API_PATH = "login";
export const AUTH_USER_API_PATH = "auth_user";

export const LOCATIONS_API_PATH = "location";
export const GET_ALL_LOCATIONS_API_PATH = `${LOCATIONS_API_PATH}/all`;
export const GET_SINGLE_LOCATION_API_PATH = `${LOCATIONS_API_PATH}?id={locationId}`;
export const ADD_LOCATIONS_API_PATH = `${LOCATIONS_API_PATH}/add`;
export const EDIT_LOCATION_API_PATH = `${LOCATIONS_API_PATH}/edit?id={locationId}`;
export const DELETE_LOCATION_API_PATH = `${LOCATIONS_API_PATH}?id={locationId}`;

export const BUILDINGS_API_PATH = "building";
export const GET_ALL_BUILDINGS_API_PATH = `${BUILDINGS_API_PATH}/all`;
export const GET_SINGLE_BUILDING_API_PATH = `${BUILDINGS_API_PATH}?id={buildingId}`;
export const ADD_BUILDINGS_API_PATH = `${BUILDINGS_API_PATH}/add`;
export const EDIT_BUILDING_API_PATH = `${BUILDINGS_API_PATH}/edit?id={buildingId}`;
export const DELETE_BUILDING_API_PATH = `${BUILDINGS_API_PATH}?id={buildingId}`;

export const SHELVES_API_PATH = "shelf";
export const GET_ALL_SHELVES_API_PATH = `${SHELVES_API_PATH}/all`;
export const GET_SINGLE_SHELF_API_PATH = `${SHELVES_API_PATH}?id={shelfId}`;
export const ADD_SHELVES_API_PATH = `${SHELVES_API_PATH}/add`;
export const EDIT_SHELF_API_PATH = `${SHELVES_API_PATH}/edit?id={shelfId}`;
export const DELETE_SHELF_API_PATH = `${SHELVES_API_PATH}?id={shelfId}`;

export const BINS_API_PATH = "bin";
export const GET_ALL_BINS_API_PATH = `${BINS_API_PATH}/all`;
export const GET_SINGLE_BIN_API_PATH = `${BINS_API_PATH}?id={binId}`;
export const ADD_BINS_API_PATH = `${BINS_API_PATH}/add`;
export const EDIT_BIN_API_PATH = `${BINS_API_PATH}/edit?id={binId}`;
export const DELETE_BIN_API_PATH = `${BINS_API_PATH}?id={binId}`;

export const PRODUCTS_API_PATH = "product-item";
export const GET_ALL_PRODUCTS_API_PATH = `${PRODUCTS_API_PATH}/all`;
export const GET_SINGLE_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}?id={productId}`;
export const ADD_PRODUCTS_API_PATH = `${PRODUCTS_API_PATH}/add`;
export const EDIT_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/edit?id={productId}`;
export const DELETE_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}?id={productId}`;
export const APPLY_DISCOUNT_PRICE_TO_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/apply-discounted-price?product_item_id={productId}&discount_price={discountPrice}`;
export const CHANGE_INVENTORY_GROUP_FOR_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/change-inventory-group?product_item_id={productId}&inventory_group_id={inventoryGroupId}`;
export const MARK_PRODUCT_AS_SOLD_API_PATH = `${PRODUCTS_API_PATH}/mark-as-sold?id={productId}`;
export const MARK_PRODUCT_AS_DAMAGED_API_PATH = `${PRODUCTS_API_PATH}/mark-as-damaged?id={productId}`;
export const TRANSFER_FROM_ANY_TO_RESTOCK_API_PATH = `${PRODUCTS_API_PATH}/transfer-from-any-to-restock?product_item_id={productId}&building_id={buildingId}`;
export const TRANSFER_FROM_INVENTORY_TO_INVENTORY_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/transfer-from-inventory-to-inventory?product_item_id={productId}&shelf_id={shelfId}`;
export const TRANSFER_FROM_INVENTORY_TO_MANIFEST_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/transfer-from-inventory-to-manifest?product_item_id={productId}&manifest_id={manifestId}&lot={lot}`;

export const CATEGORIES_API_PATH = "category";
export const GET_ALL_CATEGORIES_API_PATH = `${CATEGORIES_API_PATH}/all`;
export const GET_SINGLE_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}?id={productCategoryId}`;
export const ADD_CATEGORIES_API_PATH = `${CATEGORIES_API_PATH}/add`;
export const EDIT_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}/edit?id={productCategoryId}`;
export const DELETE_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}?id={productCategoryId}`;

export const DEPARTMENTS_API_PATH = "department";
export const GET_ALL_DEPARTMENTS_API_PATH = `${DEPARTMENTS_API_PATH}/all`;
export const GET_SINGLE_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}?id={departmentId}`;
export const ADD_DEPARTMENTS_API_PATH = `${DEPARTMENTS_API_PATH}/add`;
export const EDIT_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}/edit?id={departmentId}`;
export const DELETE_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}?id={departmentId}`;

export const PRODUCT_CONDITIONS_API_PATH = "product-condition";
export const GET_ALL_PRODUCT_CONDITIONS_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/all`;
export const GET_SINGLE_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}?id={productConditionId}`;
export const ADD_PRODUCT_CONDITIONS_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/add`;
export const EDIT_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/edit?id={productConditionId}`;
export const DELETE_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}?id={productConditionId}`;

export const DELIVERY_LOADS_API_PATH = "delivery-load";
export const GET_ALL_DELIVERY_LOADS_API_PATH = `${DELIVERY_LOADS_API_PATH}/all`;
export const GET_SINGLE_DELIVERY_LOAD_API_PATH = `${DELIVERY_LOADS_API_PATH}?id={deliveryLoadId}`;
export const ADD_DELIVERY_LOADS_API_PATH = `${DELIVERY_LOADS_API_PATH}/add`;
export const EDIT_DELIVERY_LOAD_API_PATH = `${DELIVERY_LOADS_API_PATH}/edit?id={deliveryLoadId}`;
export const DELETE_DELIVERY_LOAD_API_PATH = `${DELIVERY_LOADS_API_PATH}?id={deliveryLoadId}`;

export const VENDORS_API_PATH = "vendor";
export const GET_ALL_VENDORS_API_PATH = `${VENDORS_API_PATH}/all`;
export const GET_SINGLE_VENDOR_API_PATH = `${VENDORS_API_PATH}?id={vendorId}`;
export const ADD_VENDORS_API_PATH = `${VENDORS_API_PATH}/add`;
export const EDIT_VENDOR_API_PATH = `${VENDORS_API_PATH}/edit?id={vendorId}`;
export const DELETE_VENDOR_API_PATH = `${VENDORS_API_PATH}?id={vendorId}`;

export const INTEGRATIONS_API_PATH = "integration";
export const GET_ALL_INTEGRATIONS_API_PATH = `${INTEGRATIONS_API_PATH}/all`;

export const PRODUCT_DISCOUNT_RULES_API_PATH = "product-discount-rule";
export const GET_ALL_PRODUCT_DISCOUNT_RULES_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}/all`;
export const GET_SINGLE_PRODUCT_DISCOUNT_RULE_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}?id={productDiscountRuleId}`;
export const ADD_PRODUCT_DISCOUNT_RULES_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}/add`;
export const EDIT_PRODUCT_DISCOUNT_RULE_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}/edit?id={productDiscountRuleId}`;
export const DELETE_PRODUCT_DISCOUNT_RULE_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}?id={productDiscountRuleId}`;
export const GET_PRODUCT_DISCOUNT_RULES_THAT_APPLY_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}/discounted-prices-for-inventory-item?product_item_id={productItemId}`;

export const INVENTORY_GROUPS_API_PATH = "inventory-group";
export const GET_ALL_INVENTORY_GROUPS_API_PATH = `${INVENTORY_GROUPS_API_PATH}/all`;
export const GET_SINGLE_INVENTORY_GROUP_API_PATH = `${INVENTORY_GROUPS_API_PATH}?id={inventoryGroupId}`;
export const ADD_INVENTORY_GROUPS_API_PATH = `${INVENTORY_GROUPS_API_PATH}/add`;
export const EDIT_INVENTORY_GROUP_API_PATH = `${INVENTORY_GROUPS_API_PATH}/edit?id={inventoryGroupId}`;
export const DELETE_INVENTORY_GROUP_API_PATH = `${INVENTORY_GROUPS_API_PATH}?id={inventoryGroupId}`;

export const MANIFESTS_API_PATH = "manifest";
export const GET_ALL_MANIFESTS_API_PATH = `${MANIFESTS_API_PATH}/all`;
export const GET_SINGLE_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}?id={manifestId}`;

export const MANIFEST_GROUPS_API_PATH = "manifest-group";
export const GET_ALL_MANIFEST_GROUPS_API_PATH = `${MANIFEST_GROUPS_API_PATH}/all`;
export const GET_SINGLE_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}?id={manifestGroupId}`;

export const COMMON_API_PATH = "common";
export const GET_INVENTORY_ITEMS_SUPPORTING_DATA_API_PATH = `${COMMON_API_PATH}/get-supporting-data-inventory-items`;
export const GET_MANIFESTS_SUPPORTING_DATA_API_PATH = `${COMMON_API_PATH}/get-supporting-data-manifests`;

export const PRODUCT_INVENTORY_RECORD_API_PATH = "product-inventory-record";
export const GET_HISTORY_FOR_INVENTORY_PRODUCT_ITEM_API_PATH = `${PRODUCT_INVENTORY_RECORD_API_PATH}/all-simple-history?product_item_ids={inventoryItemId}`;

export const PALLETS_API_PATH = "pallet";
export const GET_ALL_PALLETS_API_PATH = `${PALLETS_API_PATH}/all`;
export const GET_SINGLE_PALLET_API_PATH = `${PALLETS_API_PATH}?id={palletId}`;
export const ADD_PALLETS_API_PATH = `${PALLETS_API_PATH}/add`;
export const EDIT_PALLET_API_PATH = `${PALLETS_API_PATH}/edit?id={palletId}`;
export const MARK_PALLET_AS_DAMAGED_API_PATH = `${PALLETS_API_PATH}/mark-as-damaged?id={palletId}`;
export const MARK_PALLET_AS_SOLD_API_PATH = `${PALLETS_API_PATH}/mark-as-sold?id={palletId}`;
export const CHANGE_MANIFEST_GROUP_FOR_PALLET_API_PATH = `${PALLETS_API_PATH}/change-manifest-group?id={palletId}`;
export const TRANSFER_PALLET_TO_MANIFEST_API_PATH = `${PALLETS_API_PATH}/move-to-manifest?id={palletId}&manifest_id={manifestId}&product_condition_id={productConditionId}&lot={lot}&section={section}`;
export const DELETE_PALLET_API_PATH = `${PALLETS_API_PATH}?id={palletId}`;
