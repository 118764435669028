export const defaultPagination = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: ["10", "20", "25"],
}

export const displayDateTimeFormat = 'MM/DD/YYYY hh:mm A';
export const displayNoYearDateTimeFormat = 'MM/DD hh:mm A';
export const chatDisplayDateTimeFormat = 'MM/DD hh:mm A';
export const backendDisplayDateTimeFormat = "YYYY-MM-DD HH:mm:ss";

export const AUTHORIZATION_KEY = "mfs-inventory-token";

export const DEFAULT_PAGINATION = {
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
};

export const DATE_STRING_FORMAT_BACKEND = "YYYY-MM-DD";

export const DISPLAY_FULL_DATETIME_FORMAT = "MM/DD/YYYY hh:mm A";

export const DATETIME_BACKEND_FORMAT = "YYYY-MM-DDTHH:mm:ss";